/*
    Common CSS
    For light theme specific CSS use .hlm-light as root class
    For dark theme specific CSS use .hlm-dark as root class
*/
.mouseHand {
    cursor: pointer;
}

.app-title-small {
    padding: 10px;
    height: 64px;
    margin-left: 8px;
}

.app-title-big {
    max-width: 164px;
    padding: 16px 24px;
}

.app-logo-header {
    height: 46px;
}

.app-title {
    color: #663399;
    font-size: 14px;
    font-weight: bold;
}

.hlm-light .ant-layout-sider-trigger {
    color: #000;
}

.system .ant-card-body {
    padding: 5px;
}

.system .ant-page-header-heading {
    display: none;
}

.system .ant-page-header {
    padding: 5px;
}

.engagement .ant-card-body {
    padding: 0px;
}

/*
    Assessment Cards
*/

.tmlncardttl .ant-card-head .ant-btn-link {
    font-weight: bold;
}

.tmlncardttl .ant-card-head .ant-btn-link:hover {
    color: #177ddc;
}

.otrtmln .inrcard .ant-card-extra .ant-typography {
    color: #fff;
    padding: 3px 10px;
    border-radius: 2px;
}

.otrtmln .inrcard .ant-card-extra .ant-typography-success {
    background-color: green;
}

.otrtmln .inrcard .ant-card-extra .ant-typography-danger {
    background-color: red;
}

.otrtmln .inrcard .ant-card-extra .ant-typography-warning {
    background-color: #d89614;
}

.inrcard .ant-card-actions .hide,
.hide {
    display: none;
}

/*
    State and Navigation buttons
*/
.nvgn-btn {
    margin: 0.6rem;
    min-width: 5.5rem;
}

.nvgn-btn:hover {
    color: #fff;
}

.stt-btn-sngl {
    margin-right: 0.5rem;
}

.shwevl .ant-radio-checked+span,
.shwevl .ant-checkbox-checked+span {
    color: orange;
}

.shwevl .crctopt .ant-radio+span,
.shwevl .crctopt .ant-checkbox+span {
    color: green;
}

.navbtn {
    text-align: right;
}

/* Styling for Form Buttons with state buttons */
.frm-wt-st-btn .stt-frm-btn-st {
    float: left;
    text-align: left;
    margin-left: 1em;
}

.frm-wt-st-btn .stt-frm-btn-frm {
    float: right;
    text-align: right;
}

.role-checkbox {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
}

/*
        Sign-in Buttons
*/
#signin-button {
    border-color: #663399;
    background: #663399;
}

#signin-button:hover,
#signin-button:focus {
    border-color: #aa77dd;
    background: #aa77dd;
}

/*
    Navigation
*/
nav.ant-breadcrumb span.ant-breadcrumb-link .anticon {
    margin-right: 4px;
}

/*
    Mermaid Modal
*/
.hstrymdl.light .ant-modal-content {
    border: 1px solid #000;
}

.hstrymdl.dark .ant-modal-content {
    border: 1px solid #fff;
    box-shadow: 0 6px 16px 0 rgb(255 255 255 / 8%), 0 3px 6px -4px rgb(255 255 255 / 12%), 0 9px 28px 8px rgb(255 255 255 / 5%);
    background-color: #000;
}

.hstrymdl.dark .ant-modal-confirm-body .ant-modal-confirm-title {
    color: #fff;
}

/*
    Timeline View Modal
*/
.hlm-light .ant-modal .ant-timeline-item-head {
    background-color: transparent;
}

.hlm-dark .ant-modal .ant-timeline-item-head {
    background-color: #222;
}

.hlm-light .ant-modal .ant-timeline-item-tail {
    color: #000;
}

.hlm-dark .ant-modal .ant-timeline-item-tail {
    color: #eee;
}

/* Error State */
#hstrymrmadmdl #state-error-0 rect {
    fill: red;
}

/* Info Corner Overrides */
.infosec .ant-card-body {
    padding: 0;
}

.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: red;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

/* Groups Show Page */
.grpmmbrs {
    color: #177ddc;
    padding: 2em;
}

.hlm-light .grpmmbrs {
    background-color: rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.hlm-dark .grpmmbrs {
    background-color: #303030;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
}

.grpmmbrs .ant-typography.ant-typography-disabled {
    color: #177ddc;
}

/* MD Content rendered in dark mode in drawers */
.ant-drawer .ant-drawer-content .hlm-md.dark {
    color: #fff;
}

/* Hide the overlay of DEV server - In Dev environment only*/
body>#webpack-dev-server-client-overlay {
    display: none;
}